<template>
  <div class="custom-content">
    <template v-if="isString">{{ content }}</template>
    <template v-if="isValidComponent">
      <component :is="content.component" v-bind="contentProps"></component>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    isValidComponent () {
      return this.content && this.content.component && typeof this.content.component === 'object'
    },
    isString () {
      return typeof this.content === 'string'
    },
    contentProps () {
      return this.content && this.content.props ? this.content.props : null
    }
  }
}
</script>

<style scoped>

</style>
