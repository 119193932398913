<template>
  <v-modal :title="title">
    <div class="modal-body" @keydown.enter="handleEnterKey">
      <template v-if="ready">
        <div class="mb-3" v-if="beforeContent">
          <custom-content :content="beforeContent"></custom-content>
        </div>

        <v-dynamic-form :locale="locale" :fields="fields" :initial-values="formInitialValues" :errors="errors" @change="handleChange"></v-dynamic-form>

        <div class="mt-3" v-if="afterContent">
          <custom-content :content="afterContent"></custom-content>
        </div>
      </template>
      <template v-else>
        Loading...
      </template>
    </div>
    <div class="modal-footer" v-if="ready">
      <button type="button" class="btn btn-secondary" @click="$emit('cancel')">{{ cancelLabel }}</button>
      <button type="button" class="btn btn-primary" @click="handleSubmit" :disabled="submitting">{{ confirmLabel }}</button>
    </div>
  </v-modal>
</template>

<script>
import CustomContent from './CustomContent'

export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'Confirm'
    },
    locale: {
      type: String,
      required: false,
      default: 'en'
    },
    fields: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    initialValues: {
      type: [Object, Function],
      required: false,
      default () {
        return {}
      }
    },
    onSubmit: {
      type: Function,
      required: true
    },
    afterContent: {
      required: false,
      type: [Object, String]
    },
    beforeContent: {
      required: false,
      type: [Object, String]
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    confirmLabel: {
      type: String,
      default: 'Confirm'
    },
    disableEnterKey: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CustomContent
  },
  data () {
    return {
      ready: false,
      submitting: false,
      formInitialValues: {},
      form: {},
      errors: {}
    }
  },
  methods: {
    async waitForInitialValues () {
      this.formInitialValues = await this.initialValues()
      this.ready = true
    },
    handleChange (values) {
      this.form = values
    },
    handleEnterKey () {
      if (!this.disableEnterKey) {
        this.handleSubmit()
      }
    },
    handleSubmit () {
      this.submitting = true

      if (typeof this.onSubmit === 'function') {
        this.onSubmit({
          values: this.form,
          confirm: this.confirm,
          setErrors: this.setErrors
        })
      }
    },
    setErrors (errors = {}) {
      this.errors = errors
      this.submitting = false
    },
    confirm (data) {
      this.$emit('confirm', data)
    }
  },
  created () {
    if (typeof this.initialValues === 'function') {
      this.waitForInitialValues()
    } else {
      this.formInitialValues = this.initialValues
      this.ready = true
    }
  }
}
</script>
